import React from 'react';
import Layout from '../components/Layout';
import { navigate } from "gatsby";
import ocBanner from "../images/oc_baner.png";
import polandMap from "../images/mapa.png";
import chart from "../images/chart.png";
import DotListItem from '../components/DotListItem';

import ic_story_1 from "../images/ic_story_1.png";
import ic_story_2 from "../images/ic_story_2.png";
import ic_story_3 from "../images/ic_story_3.png";
import ic_story_4 from "../images/ic_story_4.png";
import ic_story_5 from "../images/ic_story_5.png";
import ic_story_6 from "../images/ic_story_6.png";
import ButtonAction from '../components/ButtonAction';


const goTo = (e) => {
    e.preventDefault();
    navigate('/replacementCar/');
}

const ServicePage = () => {
    const data = {
        dotItem:['pomoc w zabezpieczeniu miejsca zdarzenia', 'wsparcie podczas rozmów ze sprawcą wypadku',
        'odholowanie i naprawę uszkodzonego samochodu',
        'podstawienie auta zastępczego',
        'wycenienie szkód w warsztacie',
        'pośrednictwa w rozmowach z ubezpieczycielem'],
        boxWithIcon: [
            {imgSrc: ic_story_1, text: 'Od zawsze kręciły nas auta. Na początku chodziło dokładnie o poszczególne ich elementy - zajmowaliśmy się handlem części karoserii, opon, skrzyń biegów czy całych silników. Z racji położenia we wschodniej Polsce, naszym głównym rynkiem zbytu od początku była Białoruś. Kupowaliśmy więc i sprzedawaliśmy tam części samochodów, po jakimś czasie orientując się, że można ten biznes w ciekawy sposób rozwinąć.'},
            {imgSrc: ic_story_2, text: 'Wynajęliśmy halę, zajęliśmy się handlem na szerszą skalę, zaczęliśmy sprowadzać samochody, remontować je, naprawiać uszkodzone części. W tym celu potrzebna była nam laweta. Jednak nawet nie sądziliśmy, jak ogromną zmianą w naszym biznesie będzie jej zakup…'},
            {imgSrc: ic_story_3, text: 'Pewnego razu Paweł jechał nią po samochód i przejeżdżał przez miejsce, w którym miała miejsce kolizja. Policjanci skorzystali z okazji, zatrzymali go i kazali odholować uszkodzone auto. Jego właściciel okazał się być pierwszym klientem AutopomOCnych.'},
            {imgSrc: ic_story_4, text: 'Auto zostało odholowane, wystawiliśmy fakturę, którą opłacił ubezpieczyciel sprawcy kolizji, a następnie te pieniądze trafiły do nas. Szybko zarobiona gotówka ucieszyła nas, ale jeszcze nie do końca wiedzieliśmy, co dalej z tym zrobić.'},
            {imgSrc: ic_story_5, text: 'Jakiś czas później odwiedzaliśmy w warsztacie dobrego znajomego, którego hobby to słuchanie opowieści z CB Radia. Stamtąd dowiedzieliśmy się o stłuczce i postanowiliśmy powtórzyć działanie sprzed kilku tygodni i trochę zarobić. No i się zaczęło…'},
            {imgSrc: ic_story_6, text: 'Postawiliśmy sobie pytanie: <b>czego jeszcze potrzebuje kierowca, który był poszkodowanym w wypadku?</b> Doszliśmy do wniosku, że skoro znamy się na samochodach, możemy je naprawiać. Jeśli mielibyśmy własną flotę, moglibyśmy wypożyczać auta zastępcze. Potem doszły do tego wyceny uszkodzeń (które realnie zawsze były wyższe od tych stworzonych przez rzeczoznawcę ubezpieczyciela) i pomoc w zdobyciu wyższego odszkodowania.'}
        ]
    }
    
    return (
        <Layout>
       <div className="main-wrapper">
            <div className="d-flex flex-row align-items-center mb-8">
                <div className="d-flex flex-column w-50"><p>
                Od kilku lat pomagamy ludziom z problemami na drodze. Wypadek, stłuczka, kolizja? Nie każdy musi wiedzieć, jak zabezpieczyć miejsce zdarzenia, jak rozmawiać z policją, ubezpieczycielem, skąd wziąć auto zastępcze i lawetę, na ile wycenić poziom uszkodzenia samochodu.</p>
                <h1>My wiemy. I chętnie zrobimy to za Ciebie.</h1>
                </div>
                <div className="d-flex w-50">
                    <div className="w-100">
                    <img className="w-100" alt="" src={ocBanner}></img>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row mb-8 align-items-center">
                <div className="d-flex w-50">
                    <div className="w-100">
                        <img className="w-100" alt="" src={polandMap}></img>
                    </div>
                </div>
                <div className="d-flex w-50">
                    <p>Na terenie Białej Podlaskiej i w obrębie 100 kilometrów od niej pomagamy wszystkim kierowcom, którym coś złego przydarzyło się w czasie podróży - tej krótkiej, z pracy do domu, a także dłuższej na wakacje. Wiemy, jak ważne jest zaufanie i jak bardzo trzeba się starać, żeby go nie zawieść.</p>
                </div>
            </div>
            <div className="d-flex flex-column">
                <h1>Dlatego jeśli zaufasz nam i zadzwonisz do nas w chwili wypadku, otrzymasz:</h1>
                <div className="d-flex justify-content-center">
                    <div className="w-50">
                    <div className="line__box d-flex ">
                        <div className="line__dashed line__dashed--black"></div>
                    </div>
                    </div>
                </div>
                <div className="d-flex flex-row mt-8 mb-4">
                    <div className="row d-flex flex-wrap">
                        {data.dotItem.map((x, index) => 
                             <div key={index} className="col-3 d-flex flex-column">
                                <DotListItem  text={x}></DotListItem>
                                </div>
                            )}
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row align-items-top mt-8">
                <div className="d-flex w-50 pr-2">
                    <div className="d-flex flex-column">
                        <h1 className="mb-2"> I to wszystko... za darmo.</h1>
                        <p>Na nasze wynagrodzenie składa się procent od zyskanego dzięki nam wzrostu kwoty odszkodowania w stosunku do pierwszej wyceny rzeczoznawcy. Dlatego zależy nam, aby było ono jak największe, co jest korzystne zarówno dla Ciebie, jak i dla nas. Tak więc nie będziesz musiał nic płacić za wyżej wymienione usługi, a przy otrzymywaniu
i oddawaniu samochodu zastępczego nawet
nie wyciągniesz portfela.</p>
                    </div>
                </div>
                <div className="d-flex w-50 flex-column">
                <div className="w-100 d-flex justify-content-center">
                    <img className="w-75" alt="" src={chart}></img>
                    </div>
<h1 className="d-flex">Kwota wzrostu odszkodowania</h1>
                </div>
            </div>
            <div className="d-flex flex-column mt-8">
                <h1>Kim jesteśmy, skąd przyjezdzamy?</h1>
                <div className="d-flex">
                    <div className="w-50">
                    <div className="line__box d-flex ">
                        <div className="line__dashed line__dashed--black"></div>
                    </div>
                    </div>
                </div>
                <div className="d-flex flex-column mt-8">
                    {data.boxWithIcon.map(({imgSrc, text}, index) =>
                        <div key={index} className="mt-2 mb-4">
                            <div className="d-flex justify-content-center">
                                <img className="d-flex" alt="" src={imgSrc}></img>
                            </div>
                            <div className="d-flex">
                                <p>{text}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            </div>
            <div className="blue-gradient-wrapper py-6">
            <div className="main-wrapper">
                    <div className="d-flex flex-row align-items-top">
                        <div className="d-flex w-50 pr-2">
                            <p className="white-color">A najlepsze w tym wszystkim było to, że klient nie musiał nic płacić - wszystkie koszty pokrywało ubezpieczenie - sytuacja win-win. Szybko wprowadziliśmy wszystkie działania w życie i jeszcze w tym samym roku kompleksowo obsłużyliśmy pierwszego klienta.</p>
                        </div>
                        <div className="d-flex w-50 flex-column pl-2">
                            <h1 className="white-color">Dziś mamy już kilkanaście samochodów i liczba ta ciągle rośnie. I dalej robimy to, co kochamy.</h1>
                            <div className="d-flex justify-content-center">
                                <ButtonAction label="Zobacz naszą flotę" onClick={(e) => goTo(e)}></ButtonAction>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ServicePage;