import React from "react";
import dot from "../images/dot.png";

class DotListItem extends React.Component {
    constructor(props){
        super(props);
    }
    render() {
        return(
            <div className="d-flex  flex-column">
                <div className="d-flex w-100 justify-content-center">
                    <img src={dot} alt=""></img>
                </div>
                    <p className="text-align-center">{this.props.text}</p>
            </div>
        );
    }
}

export default DotListItem;